var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
            "label-position": "left"
          }
        },
        [
          _c(
            "div",
            { staticClass: "login-content" },
            [
              _c("div", { staticClass: "title-container" }, [
                _c(
                  "h3",
                  { staticClass: "title" },
                  [
                    _c("svg-icon", {
                      staticClass: "login_logo",
                      attrs: { "icon-class": "l_logo" }
                    }),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("login.title")) +
                        "\n\t\t\t\t"
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [
                      _c("svg-icon", {
                        staticClass: "svg-active",
                        attrs: { "icon-class": "user" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: _vm.$t("login.username"),
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      "auto-complete": "off"
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [
                      _c("svg-icon", {
                        staticClass: "svg-active",
                        attrs: { "icon-class": "password" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: _vm.$t("login.password"),
                      name: "password",
                      tabindex: "2",
                      "auto-complete": "off"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      }
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password" ? "eye" : "eye-open"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.verifyCodeType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "googleAuth" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            staticClass: "svg-active",
                            attrs: { "icon-class": "security" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "security",
                        attrs: {
                          placeholder: "请输入谷歌验证码",
                          name: "googleAuth",
                          type: "text",
                          tabindex: "3"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          }
                        },
                        model: {
                          value: _vm.loginForm.googleAuth,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "googleAuth", $$v)
                          },
                          expression: "loginForm.googleAuth"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.verifyCodeType === 3 && _vm.moonShield === 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "googleAuth" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            staticClass: "svg-active",
                            attrs: { "icon-class": "security" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "security",
                        attrs: {
                          placeholder: "请输入动态密码",
                          name: "googleAuth",
                          type: "text",
                          tabindex: "3"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          }
                        },
                        model: {
                          value: _vm.loginForm.googleAuth,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "googleAuth", $$v)
                          },
                          expression: "loginForm.googleAuth"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "login-btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("login.logIn")))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "version" }, [
        _vm._v("version: " + _vm._s(_vm.version))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }