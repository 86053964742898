"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.find-index");

var _defineProperty2 = _interopRequireDefault(require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _router = require("@/router");

var _layout = _interopRequireDefault(require("@/layout"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: [],
  userBtns: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, value) {
    state.addRoutes = value.accessedRoutes;
    state.routes = _router.constantRoutes.concat(value.accessedRoutes);
    state.userBtns = value.userBtns;
  },
  CLEAR_ROUTES: function CLEAR_ROUTES(state) {
    state.addRoutes = [];
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    // 用户路由
    var userRoutes = roles.filter(function (val) {
      return val.type === '0';
    }); // 用户按钮权限

    var userBtns = roles // .filter((val) => val.type === '1')
    .map(function (val) {
      return val.permissionName;
    }); // permissionName 为组件名称

    return new Promise(function (resolve) {
      var accessedRoutes = null;
      var parentRoutes = [];
      userRoutes.forEach(function (element) {
        if (element.parentId === '0') {
          parentRoutes.push({
            id: element.id,
            path: element.component,
            name: element.permissionName,
            alwaysShow: true,
            component: _layout.default,
            meta: {
              title: element.permissionName,
              icon: element.icon
            },
            children: []
          });
        }
      });
      userRoutes.forEach(function (element) {
        if (element.parentId > '0') {
          var index = parentRoutes.findIndex(function (val) {
            return val.id === element.parentId;
          });

          if (index > -1) {
            parentRoutes[index].children.push({
              path: element.component,
              name: element.permissionName,
              // component: () => import(`@/views${element.component}`),
              component: function component(resolve) {
                return require(["@/views".concat(element.component)], resolve);
              },
              meta: {
                title: element.permissionName,
                icon: element.icon
              }
            });
          }
        }
      });
      parentRoutes.forEach(function (element) {
        delete element.id;

        if (element.children && element.children.length === 0) {
          var path = element.path && element.path.split('/')[1];
          path = "".concat(element.path, "/").concat(path);
          element.children.push({
            path: path,
            name: element.name + 'List',
            // 修复子路由和父路由重名的问题
            // component: () => import(`@/views${element.path}`),
            component: function component(resolve) {
              return require(["@/views".concat(element.path)], resolve);
            },
            meta: {
              title: element.name,
              icon: element.icon
            }
          });
        }

        if (element.children && element.children.length !== 0) {
          element.redirect = "".concat(element.children[0].path);
        }
      });
      accessedRoutes = parentRoutes.concat([{
        path: '*',
        redirect: '/404',
        hidden: true
      }]) || [];
      commit('SET_ROUTES', {
        accessedRoutes: accessedRoutes,
        userBtns: userBtns
      });
      resolve(accessedRoutes);
    });
  },
  clearRoutes: function clearRoutes(_ref2) {
    var commit = _ref2.commit;
    commit('CLEAR_ROUTES');
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;