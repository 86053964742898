"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _storejs = _interopRequireDefault(require("storejs"));

var _popup = _interopRequireDefault(require("element-ui/src/utils/popup"));

var _locale = _interopRequireDefault(require("element-ui/src/mixins/locale"));

var _button = _interopRequireDefault(require("element-ui/packages/button"));

var _dom = require("element-ui/src/utils/dom");

var _locale2 = require("element-ui/src/locale");

var _ariaDialog = _interopRequireDefault(require("element-ui/src/utils/aria-dialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var UserInfoKey = 'zr-user-key';
var messageBox;
var typeMap = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
};
var _default = {
  mixins: [_popup.default, _locale.default],
  props: {
    modal: {
      default: true
    },
    lockScroll: {
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      default: true
    },
    closeOnPressEscape: {
      default: true
    },
    closeOnHashChange: {
      default: true
    },
    center: {
      default: false,
      type: Boolean
    },
    roundButton: {
      default: false,
      type: Boolean
    }
  },
  components: {
    ElButton: _button.default
  },
  computed: {
    icon: function icon() {
      var type = this.type,
          iconClass = this.iconClass;
      return iconClass || (type && typeMap[type] ? "el-icon-".concat(typeMap[type]) : '');
    },
    confirmButtonClasses: function confirmButtonClasses() {
      return "el-button--primary ".concat(this.confirmButtonClass);
    },
    cancelButtonClasses: function cancelButtonClasses() {
      return "".concat(this.cancelButtonClass);
    }
  },
  methods: {
    getSafeClose: function getSafeClose() {
      var _this2 = this;

      var currentId = this.uid;
      return function () {
        _this2.$nextTick(function () {
          if (currentId === _this2.uid) _this2.doClose();
        });
      };
    },
    doClose: function doClose() {
      var _this3 = this;

      if (!this.visible) return;
      this.visible = false;
      this._closing = true;
      this.onClose && this.onClose();
      messageBox.closeDialog(); // 解绑

      if (this.lockScroll) {
        setTimeout(this.restoreBodyStyle, 200);
      }

      this.opened = false;
      this.doAfterClose();
      setTimeout(function () {
        if (_this3.action) _this3.callback(_this3.action, _this3);
      });
    },
    handleWrapperClick: function handleWrapperClick() {
      if (this.closeOnClickModal) {
        this.handleAction(this.distinguishCancelAndClose ? 'close' : 'cancel');
      }
    },
    handleInputEnter: function handleInputEnter() {
      if (this.inputType !== 'textarea') {
        return this.handleAction('confirm');
      }
    },
    handleAction: function handleAction(action) {
      if (this.$type === 'prompt' && action === 'confirm' && !this.validate()) {
        return;
      }

      this.action = action;

      if (typeof this.beforeClose === 'function') {
        this.close = this.getSafeClose();
        this.beforeClose(action, this, this.close);
      } else {
        this.doClose();
      }
    },
    validate: function validate() {
      if (this.$type === 'prompt') {
        var inputPattern = this.inputPattern;

        if (inputPattern && !inputPattern.test(this.inputValue || '')) {
          this.editorErrorMessage = this.inputErrorMessage || (0, _locale2.t)('el.messagebox.error');
          (0, _dom.addClass)(this.getInputElement(), 'invalid');
          return false;
        }

        var inputValidator = this.inputValidator;

        if (typeof inputValidator === 'function') {
          var validateResult = inputValidator(this.inputValue);

          if (validateResult === false) {
            this.editorErrorMessage = this.inputErrorMessage || (0, _locale2.t)('el.messagebox.error');
            (0, _dom.addClass)(this.getInputElement(), 'invalid');
            return false;
          }

          if (typeof validateResult === 'string') {
            this.editorErrorMessage = validateResult;
            (0, _dom.addClass)(this.getInputElement(), 'invalid');
            return false;
          }
        }
      }

      this.editorErrorMessage = '';
      (0, _dom.removeClass)(this.getInputElement(), 'invalid');
      return true;
    },
    getFirstFocus: function getFirstFocus() {
      var btn = this.$el.querySelector('.el-message-box__btns .el-button');
      var title = this.$el.querySelector('.el-message-box__btns .el-message-box__title');
      return btn || title;
    },
    getInputElement: function getInputElement() {
      var inputRefs = this.$refs.input.$refs;
      return inputRefs.input || inputRefs.textarea;
    },
    handleClose: function handleClose() {
      this.handleAction('close');
    },
    clearInput: function clearInput() {
      document.getElementById('value1').value = '';
      document.getElementById('value2').value = '';
      document.getElementById('value3').value = '';
      document.getElementById('value4').value = '';
      document.getElementById('value5').value = '';
      document.getElementById('value6').value = '';
      document.getElementById('value1').focus();
    },
    moonShieldAuth: function moonShieldAuth() {
      var _this4 = this;

      var num1 = document.getElementById('value1').value;
      var num2 = document.getElementById('value2').value;
      var num3 = document.getElementById('value3').value;
      var num4 = document.getElementById('value4').value;
      var num5 = document.getElementById('value5').value;
      var num6 = document.getElementById('value6').value;
      var userInfo = _storejs.default.get(UserInfoKey) || {};
      var para = {
        type: '1',
        userName: userInfo.username.slice(2),
        moonShieldAuthCode: "".concat(num1).concat(num2).concat(num3).concat(num4).concat(num5).concat(num6)
      };
      this.$api.moonShieldAuth(para).then(function (resp) {
        console.log('resp', resp);

        if (resp.data === 1) {
          _this4.handleAction('confirm');
        } else if (resp.data === 0) {
          _this4.clearInput();

          _this4.$message.error('海月盾验证码错误');
        } else if (resp.data === -1) {
          _this4.$message.error('没有权限');
        } else if (resp.data === 2) {
          _this4.clearInput();

          _this4.$message.error('海月盾已被锁定，请联系管理员解锁！');
        }
      }).catch(function () {
        _this4.clearInput();
      });
    }
  },
  watch: {
    inputValue: {
      immediate: true,
      handler: function handler(val) {
        var _this5 = this;

        this.$nextTick(function (_) {
          if (_this5.$type === 'prompt' && val !== null) {
            _this5.validate();
          }
        });
      }
    },
    visible: function visible(val) {
      var _this6 = this;

      console.log('val', val);

      if (!val) {
        this.clearInput();
      }

      if (val) {
        this.uid++;

        if (this.$type === 'alert' || this.$type === 'confirm') {
          this.$nextTick(function () {
            //   this.$refs.confirm.$el.focus();
            document.getElementsByClassName('seamoon-input')[0].focus();
          });
        }

        this.focusAfterClosed = document.activeElement;
        messageBox = new _ariaDialog.default(this.$el, this.focusAfterClosed, this.getFirstFocus());
      } // prompt


      if (this.$type !== 'prompt') return;

      if (val) {
        setTimeout(function () {
          if (_this6.$refs.input && _this6.$refs.input.$el) {
            _this6.getInputElement().focus();
          }
        }, 500);
      } else {
        this.editorErrorMessage = '';
        (0, _dom.removeClass)(this.getInputElement(), 'invalid');
      }
    },
    activeIndex: function activeIndex(val) {
      var str = 'value' + val;
      var dom = document.getElementById(str);
      dom.value = '';
      dom.focus();
    }
  },
  mounted: function mounted() {
    var _this7 = this;

    this.$nextTick(function () {
      if (_this7.closeOnHashChange) {
        window.addEventListener('hashchange', _this7.close);
      }

      var inputs = document.getElementsByClassName('seamoon-input');
      var _this = _this7;

      for (var i = 0; i < inputs.length; i++) {
        var c = inputs[i];

        c.oninput = function () {
          if (this.value.length > 1) {
            this.value = this.value.slice(this.value.length - 1, this.value.length);
          }

          var id = this.getAttribute('data-id');

          if (this.value) {
            if (id >= 6) {
              _this.moonShieldAuth();

              return;
            }

            _this.activeIndex = Number(id) + 1;
          }
        };
      }

      document.addEventListener('keyup', function (e) {
        if (e.keyCode === 8 || e.keyCode === 46) {
          if (_this7.activeIndex <= 1) return;
          _this7.activeIndex -= 1;
        }
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.closeOnHashChange) {
      window.removeEventListener('hashchange', this.close);
    }

    setTimeout(function () {
      messageBox.closeDialog();
    });
  },
  data: function data() {
    return {
      uid: 1,
      title: undefined,
      message: '',
      type: '',
      iconClass: '',
      customClass: '',
      showInput: false,
      inputValue: null,
      inputPlaceholder: '',
      inputType: 'text',
      inputPattern: null,
      inputValidator: null,
      inputErrorMessage: '',
      showConfirmButton: false,
      showCancelButton: false,
      action: '',
      confirmButtonText: '',
      cancelButtonText: '',
      confirmButtonLoading: false,
      cancelButtonLoading: false,
      confirmButtonClass: '',
      confirmButtonDisabled: false,
      cancelButtonClass: '',
      editorErrorMessage: null,
      callback: null,
      dangerouslyUseHTMLString: false,
      focusAfterClosed: null,
      isOnComposition: false,
      distinguishCancelAndClose: false,
      activeIndex: 1
    };
  }
};
exports.default = _default;