"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealerUserGameManageCreate = dealerUserGameManageCreate;
exports.dealerUserGameManageSearchAccount = dealerUserGameManageSearchAccount;
exports.dealerUserGameManageSearchLog = dealerUserGameManageSearchLog;
exports.dealerUserGameManageSelectDealerInfo = dealerUserGameManageSelectDealerInfo;
exports.dealerUserGameManageUpdateDealerInfo = dealerUserGameManageUpdateDealerInfo;
exports.dealerUserGameManageUpdateStatus = dealerUserGameManageUpdateStatus;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 荷官管理 - 新建
 * @param {
 * "age": 0,
 * "country": "string",
 * "dealerAccount": "string",
 * "dealerName": "string",
 * "headUrl": "string",
 * "id": 0,
 * "onPayroll": 0,
 * "sex": 0,
 * "workAt": "string"
 * }
 */
function dealerUserGameManageCreate(data) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 荷官管理 - 荷官账号列表
 * @param {
 * "dealerAccount": "string",
 * "dealerName": "string",
 * "page": "number",
 * "pageSize": "number"
 * }
 */


function dealerUserGameManageSearchAccount(params) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/searchAccount',
    method: 'get',
    params: params
  });
}
/**
 * 荷官管理 - 荷官日志查询
 * @param {
 * "dealerAccount": "string",
 * "dealerName": "string",
 * "page": "number",
 * "pageSize": "number"
 * }
 */


function dealerUserGameManageSearchLog(params) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/searchLog',
    method: 'get',
    params: params
  });
}
/**
 * 荷官管理 - 查询荷官信息
 * @param {
 * "age": 0,
 * "country": "string",
 * "dealerAccount": "string",
 * "dealerName": "string",
 * "headUrl": "string",
 * "id": 0,
 * "onPayroll": 0,
 * "sex": 0,
 * "workAt": "string"
 * }
 */


function dealerUserGameManageSelectDealerInfo(data) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/selectDealerInfo',
    method: 'post',
    data: data
  });
}
/**
 * 荷官管理 - 修改荷官信息
 * @param {
 * id: number
 * }
 */


function dealerUserGameManageUpdateDealerInfo(data) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/updateDealerInfo',
    method: 'post',
    data: data
  });
}
/**
 * 荷官管理 - 修改荷官信息
 * @param {
 *  "id": 0,
 *  "status": "string"
 * }
 */


function dealerUserGameManageUpdateStatus(data) {
  return (0, _request.default)({
    url: '/dealerUserGameManage/updateStatus',
    method: 'post',
    data: data
  });
}

var _default = {
  dealerUserGameManageCreate: dealerUserGameManageCreate,
  dealerUserGameManageSearchAccount: dealerUserGameManageSearchAccount,
  dealerUserGameManageSearchLog: dealerUserGameManageSearchLog,
  dealerUserGameManageSelectDealerInfo: dealerUserGameManageSelectDealerInfo,
  dealerUserGameManageUpdateDealerInfo: dealerUserGameManageUpdateDealerInfo,
  dealerUserGameManageUpdateStatus: dealerUserGameManageUpdateStatus
};
exports.default = _default;