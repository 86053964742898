var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("div", { staticClass: "flex-h flex-bc" }, [
        _c("h2", { staticClass: "h2-line" }, [
          _vm._v(_vm._s(_vm.$t("navbar.dashboard")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "content" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "welcome-info" }, [
              _vm._v(_vm._s(_vm.name) + "，" + _vm._s(_vm.$t("page.welcome")))
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../assets/img/index_logo.png") }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }