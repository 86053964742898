"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/core-js/modules/es6.array.iterator.js");

require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/core-js/modules/es6.promise.js");

require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/core-js/modules/es6.object.assign.js");

require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _lang = _interopRequireDefault(require("./utils/lang"));

var _index3 = require("./utils/index");

require("@/icons");

require("@/permission");

var _apiInstall = _interopRequireDefault(require("@/utils/apiInstall"));

var _vViewer = _interopRequireDefault(require("v-viewer"));

require("viewerjs/dist/viewer.css");

var _judgeBrower = require("./utils/judgeBrower");

var _index4 = require("@/components/Seamoon/index.js");

// A modern alternative to CSS resets
// global css
// icon
// permission control
(0, _judgeBrower.supportBrower)();
console.table('版本信息:', process.env.VUE_APP_GIT);
_vue.default.prototype.$seamoon = _index4.MessageBox.confirm;

_vue.default.use(_vViewer.default);

_vViewer.default.setDefaults({
  // inline: true,
  // button: true,
  // navbar: true,
  // title: true,
  toolbar: true,
  // tooltip: false,
  // movable: true,
  // zoomable: true,
  // rotatable: true,
  // scalable: true,
  // transition: true,
  // fullscreen: false,
  // keyboard: true,
  url: 'data-source'
}); // 多语言支持


_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
}); // 时间格式化


_vue.default.filter('dateformat', _index3.parseTime);

_vue.default.prototype.$t6 = _index3.tran6; // 加入api插件

_vue.default.use(_apiInstall.default);

_vue.default.config.productionTip = false; // 当前环境

_vue.default.prototype.env = process.env.NODE_ENV === 'development' ? 'dev' : 'prd';

JSON.copy = function (obj) {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    return obj;
  }
};

var _default = new _vue.default({
  el: '#app',
  i18n: _lang.default,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});

exports.default = _default;