var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu flex" },
        [
          _vm.env
            ? _c("lang-select", { staticClass: "right-menu-item hover-effect" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "download-wrap" }, [
            _c("img", {
              attrs: { src: require("@/assets/download/download.jpg") },
              on: { click: _vm.handleShowDownload }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "avatar-wrapper flex flex-cc" }, [
            _c("img", {
              staticClass: "user-avatar",
              attrs: { src: _vm.myavatar }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "name flex-cc" }, [
              _vm._v(_vm._s(_vm.name))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "login-out", on: { click: _vm.loginOut } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }