"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    dashboard: 'Dashboard',
    gamemanagement: 'Game management',
    gametype: 'Game type',
    gametypedetail: 'Game type detail',
    gametypeedit: 'Game type edit',
    gameplay: 'Game play',
    gameplaymodify: 'Game play modify',
    gameplayedit: 'Game play edit',
    gameplaydetail: 'Game play detail',
    gamedesk: 'Game desk',
    gamedesknew: 'Game desk new',
    gamedeskedit: 'Game desk edit',
    gamedeskmodify: 'Game desk modify',
    gametableopenstatusmodify: 'Open state modification',
    gamelimit: 'Game limit',
    gamelimitnew: 'Game limit new',
    gamelimitdetail: 'Game limit detail',
    gamelimitedit: 'Game limit edit',
    limitProportion: 'Game limit config',
    gamepclabel: 'Pc hall label',
    gamepclabeladjust: 'Pc label adjust',
    gamepclabeldistribution: 'Pc label distribution',
    gamemobilelabel: 'Mobile hall label',
    gamemobilelabeladjust: 'Mobile label adjust',
    gamemobilelabeldistribution: 'Mobile label distribution',
    gamemodule: 'Pc Game module',
    gamemodulenew: 'New module',
    gamemoduleedit: 'Edit module',
    gamemodulesort: 'Module sort',
    gamemodulestatus: 'Modify module status',
    gamemmodule: 'Mobile Game module',
    gamemmodulenew: 'New module',
    gamemmoduleedit: 'Edit module',
    gamemmodulesort: 'Module sort',
    gamemmodulestatus: 'Modify module status',
    gameresult: 'Game result',
    gameresultsubmit: 'Abnormal submission',
    gameexsituation: 'Game exsituation',
    gameexsituationcancel: 'Cancel the bureau',
    gameexsituationrecalc: 'Recalculation bureau',
    systemmanagement: 'System management',
    systemrole: 'Role management',
    systemrolenew: 'Role new',
    systemroleedit: 'Role edit',
    systemrolemodify: 'Role modify',
    systemroledelete: 'Role delete',
    systemaccount: 'Account management',
    systemaccountnew: 'Account new',
    systemaccountedit: 'Account edit',
    systemaccountmodify: 'Account modify',
    systemaccountpassword: 'Password modify',
    systemnotice: 'Notice management',
    systemnoticenew: 'Notice new',
    systemnoticeedit: 'Notice edit',
    systemnoticemodify: 'Notice modify',
    systemipblacklist: 'IP blacklist',
    systemipblacklistnew: 'IP blacklist new',
    systemipblacklistdelete: 'IP blacklist delete',
    systemhandlelog: 'Handle log',
    checkView: 'Check view',
    parameterConfig: 'Parameter config',
    addParameterConfig: 'Add parameter config',
    updateParameterConfig: 'Update parameter config',
    parameterChangeRecord: 'Parameter change record',
    betLimitCurrency: 'View multi-currency red limit',
    labelTopTable: 'View top table',
    labelTable: 'View unmounted tables',
    updateLabelTable: 'Update top table',
    mobilelabelTopTable: 'View top table',
    mobilelabelTable: 'View unmounted tables',
    mobileupdateLabelTable: 'Update top table'
  },
  tableColumn: {
    southIdName: 'Americas Hall Association ID',
    whetherToEnable: 'whether to enable',
    sort: 'sort',
    no: 'No',
    tableName: 'table name',
    moduleName: 'chinese name',
    chineseName: 'traditional chinese name',
    englishName: 'english name',
    vietnameseName: 'Vietnam name',
    thaiName: 'Thai name',
    tableId: 'table id',
    gameType: 'game type',
    physicalTableNumber: 'physical table number',
    videoAddress: 'video address',
    dealerSource: 'dealer source',
    redLimit: 'red limit',
    minimumBet: 'minimum bet',
    minimumBetNumber: 'minimum bet amount',
    maximumBet: 'maximum bet',
    maximumBetNumber: 'maximum bet amount',
    odds: 'odds',
    redLimitScheme: 'red limit scheme',
    redLimitSchemeName: 'red limit scheme name',
    schemeName: 'scheme name',
    detailLimit: 'detail',
    multiCurrencyLimit: 'multi currency',
    typeName: 'type name',
    play: 'play',
    betTime: 'bet time',
    waitingForCleaningTime: 'wait for clean time',
    supportMultiple: 'support multiple',
    automaticExitNumber: 'automatic exit number',
    bureauNumber: 'bureau number',
    bootNumber: 'boot number',
    tableNumber: 'table number',
    result: 'result',
    dealerAccount: 'dealer account',
    dealerName: 'dealer name',
    video: 'video',
    submitter: 'submitter',
    submissionTime: 'submission time',
    submitRemark: 'submit remark',
    gamePlay: 'game play',
    gameName: 'game name',
    gameOdds: 'game odds',
    bootsLimit: 'boots limit',
    description: 'description',
    ipSegment: 'ip segment',
    remark: 'remark',
    currency: 'currency',
    renminbi: 'Renminbi',
    account: 'account',
    userName: 'username',
    operationalContent: 'operation content',
    role: 'role',
    processingPerson: 'processing person',
    processResult: 'process result',
    processingTime: 'processing time',
    startingHandCardTime: 'starting hand card time',
    cardTime56: '5/6 card time',
    maximumCards: 'maximum cards',
    thenumberofboots: 'number of boots',
    carrierFrequency: 'carrier frequency',
    noBetAutomaticExitTime: 'no bet automatic exit time',
    autoOpenWhenTimeout: 'auto open when timeout',
    assignableRole: 'assignable role',
    assignedRole: 'assigned role',
    normal: 'normal',
    shutDown: 'shut down',
    status: 'status',
    operating: 'operating',
    venueCurrency: 'venue currency',
    currencyCode: 'currency code',
    venueCurrencyOrGameCurrency: 'venueCurrency Or gameCurrency',
    createTime: 'createTime',
    lastUpdateTime: 'lastUpdateTime',
    editCurrency: 'edit currency'
  },
  btn: {
    cancel: 'cancel',
    submit: 'submit',
    confirm: 'confirm',
    ok: 'ok',
    distributionTable: 'distribution table',
    distributionAllTable: 'distribution all table',
    cancelAllTable: 'cancel all table',
    distributionAllTableForTop: 'top all table',
    cancelAllTableForTop: 'cancel all table',
    choice: 'choice',
    search: 'search',
    reset: 'reset',
    new: 'new',
    edit: 'edit',
    detail: 'detail',
    delete: 'delete',
    remove: 'remove',
    view: 'view',
    changeRecord: 'change record',
    changePassword: 'change password',
    createAccount: 'create account',
    newAnnouncement: 'new announcement',
    abnormalSubmission: 'abnormal submission',
    exceptionHandling: 'exception handling',
    toBeProcessed: 'to be processed',
    processed: 'processed',
    creatingRole: 'creating a role',
    editRole: 'edit a role',
    batchIpDownload: 'Batch IP add import template download',
    addBlacklist: 'add blacklist',
    batchAddBlacklist: 'batch add blacklist',
    batchImportBlacklist: 'batch import blacklist',
    cancelBureau: 'cancel',
    recalculationBureau: 'recalculation',
    videoView: 'video view',
    viewAll: 'view all',
    create: 'create',
    notSet: 'not set',
    modify: 'modify'
  },
  label: {
    playera: 'Player A',
    playerb: 'Player B',
    gamePcFront: 'Game PC front end',
    gameH5Mobile: 'Game H5 mobile',
    scrollingAnnouncement: 'scrilling announcement',
    popUpAnnouncement: 'pop-up window announcement',
    name: 'name',
    password: 'password',
    newPassword: 'new password',
    comfirmPassword: 'comfirm password',
    minimumBet: 'minimum bet',
    oneTimeMinBet: 'minimum bet',
    maximumBet: 'maximum bet',
    oneTimeMaxBet: 'maximum bet',
    limitRange: 'limit range',
    idSearch: 'id search',
    tableStatus: 'table status',
    nameSearch: 'name search',
    title: 'title',
    content: 'content',
    file: 'file',
    seatMode: 'seat mode',
    announcementType: 'announcement type',
    showTime: 'show time',
    hallLabelName: 'hall label name',
    unallocatedTable: 'unallocated table',
    assigned: 'assigned',
    comfirmClose: 'comfirm close',
    bureauStatus: 'bureau status',
    bureauNumberInquiry: 'bureau number inquiry',
    time: 'time',
    second: 'second',
    playStatus: 'play status',
    creationTime: 'creation time',
    updatedTime: 'updated time',
    releaseTime: 'release time',
    announcing: 'announcing',
    unpublished: 'unpublished',
    announcementStatus: 'notice status',
    operatingTime: 'operating time',
    operationType: 'operation type',
    operationAccount: 'operation account',
    roleStatus: 'role status',
    roleName: 'role name',
    roleDescription: 'role description',
    permissionSettings: 'permission settings',
    founder: 'founder',
    updatedBy: 'updated by',
    accountStatus: 'account status',
    userAccount: 'user account',
    ipSegment: 'IP segment',
    ipScope: 'IP scope',
    startingIP: 'starting IP',
    cutoffIP: 'cutoff IP',
    oneselfBalanceLimit: 'oneself balance limit',
    shareBalanceLimit: 'share balance limit',
    seatBalanceLimit: 'seat balance limit',
    insuranceBetTime: 'insuranceBetTime',
    pointsBetTime: 'pointsBetTime',
    playerOutsTime: 'playerOutsTime',
    paid: 'paid',
    jumped: 'jumped',
    recalculated: 'recalculated',
    originalResult: 'original result',
    player: 'player',
    banker: 'banker',
    anda: 'Andar',
    baha: 'Bahar',
    xiaochou: 'joker',
    xiaochouFlower: 'joker suit',
    xiaochouPoints: 'Joker Card Points',
    common: 'common',
    di: '',
    dragonFlower: 'dragon color',
    tigerColor: 'tiger color',
    dragonPoints: 'dragon points',
    tigerPoints: 'tiger points',
    cardSuit: 'card suit',
    cardPoints: 'card points',
    label: 'label',
    dictionaryCode: 'dictionary code',
    type: 'type',
    explain: 'explain',
    value: 'value',
    defaultSetChip: 'default setting chips',
    defaultCheckChip: 'default check chips'
  },
  option: {
    all: 'all',
    selectAll: 'select all',
    enabled: 'enabled',
    disable: 'disable',
    yes: 'yes',
    no: 'no'
  },
  placeholder: {
    noSpace: 'Name cannot contain spaces',
    select: 'select',
    within500Words: 'within 500 words',
    within300Words: 'within 300 words',
    within100Words: 'within 100 words',
    within50Words: 'within 50 words',
    within30Words: 'within 30 words',
    idSearch: 'input table id',
    tableStatus: 'choose table status',
    nameSearch: 'input table name',
    enterIP: 'please enter IP',
    lastWeek: 'last week',
    lastMonth: 'last month',
    lastThreeMonths: 'last three months',
    startTime: 'start time',
    to: 'to',
    endTime: 'end time',
    startDate: 'start time',
    endDate: 'end time',
    enterNumber: 'office number',
    roleStatus: 'select role status',
    roleName: 'enter role name',
    founder: 'enter founder',
    comfirmPassword: 'comfirm password',
    publishAccount: 'publish account',
    exceptionDescription: 'exception description',
    enterNoticeTitle: 'enter the title of the announcement',
    enterNoticeContent: 'enter the announcement content',
    selectShowTime: 'select the show time',
    enterGameNumber: 'please enter the number',
    lineLimit: 'line of limit',
    enterLabel: 'please enter label name',
    enterDictionaryCode: 'please enter dictionary code',
    moduleName: 'please enter chinese name',
    chineseName: 'please enter traditional chinese name',
    englishName: 'please enter englise name',
    vietnameseName: 'please enter vietnam name',
    thaiName: 'please enter thai name'
  },
  message: {
    andabahaError1: "The number of Anda's cards can only be one more than the number of Baja's cards, please change and submit",
    andabahaError2: 'There are duplicate cards, please change and submit',
    andabahaError3: 'Anda and Baja can only have the same number of cards, please change and submit',
    andabahaError4: 'must have a card',
    andabahaError4_: 'Baja must have a card',
    andabahaError5: 'The total number of all cards cannot be > 50',
    betCountDownError: 'Please enter the betting time',
    waitCountDownError: 'Please enter the waiting time to clean up',
    rebootExitNumError: 'Please enter the number of automatic exits',
    pleaseEnterTime: 'Required, please enter the time',
    pleaseEnterNumber: 'Required, please enter a number',
    enterUserName: 'please enter user name',
    pleaseEnter: 'please enter',
    warning: 'warning',
    delete: 'whether to confirm the deletion',
    deleteCol: 'Please select the column you want to delete',
    successfulOperation: 'successful operation',
    errorNoToTopBefore: 'Can not be arranged before the top table',
    submittedSuccessfully: 'submitted successfully',
    exceptionDescription: 'Please fill in the exception description',
    afterTable: 'The table',
    enabledTable: 'Whether to confirm the activation of the table?',
    disableTable: 'Whether to confirm the suspension of the table?',
    enabledPlay: 'Whether to confirm the activation of the gameplay?',
    afterPlay: 'The gameplay',
    disablePlay: 'Whether to confirm the suspension of the gameplay?',
    frontRole: 'Whether to confirm',
    afterRole: 'Role authorization',
    openRole: 'Enable',
    closeRole: 'Disable',
    removeRole: 'delete',
    deleteRole: 'Whether to confirm the deletion of the role authorization?',
    enabledRole: 'Whether to confirm that the role authorization is enabled?',
    disableRole: 'Whether to confirm the deactivation of the role authorization?',
    afterAccount: 'The account',
    afterModule: 'The module',
    enabledAccount: 'Whether to confirm the recovery of the account?',
    disableAccount: 'Whether to confirm the suspension of the account?',
    enabledNotice: 'Whether to confirm the content of the announcement is enabled',
    disableNotice: 'Whether to confirm the suspension of the announcement content',
    enterTableName: 'name of the table',
    enterTableNo: 'physical table number',
    enterDelearData: 'dealer data source',
    enterVideoUrl: 'video address',
    selectLimit: 'choose a red limit scheme',
    oneselfBalanceLimit: 'oneself balance limit',
    shareBalanceLimit: 'shared package table conditions',
    seatBalanceLimit: 'seat quota conditions',
    seatMode: 'seat mode',
    enterRoleDescription: 'Please enter a role description',
    createdSuccessfully: 'created successfully',
    newCreateSuccess: 'Newly created successfully',
    editSuccess: 'successful editing',
    cancelSuccess: 'cancel success',
    cancelGame: 'after canceling the game, it will take effect immediately, return all bets, and confirm the cancellation of the game?',
    cancelled: 'cancelled',
    selectNoticeType: 'select the type of announcement',
    deskValidName: '4~20 characters, cn/num/en',
    deskValidName2: '4~12 characters, cn/num/en',
    roleValidName: '1~15 characters, cn/num/en',
    validName6: '2-6 characters, cn/num/en/vn/th',
    validName20: '1-20 characters, cn/num/en/vn/th/ko',
    deskValidPhysicsTableNo: '3 characters, num/en',
    deskValidNumber: '1~12 numbers, integer',
    validNumberEn12: '6~12 characters, num/en',
    validNumberEnCn: '2-6 characters, cn/num/en',
    resultValidName: '2~60 characters, cn/num/en',
    integerThan0: 'please enter an integer greater than 0',
    integerThan100: 'Please enter an integer 1-100',
    integerThan1000: 'Please enter an integer 1-1000',
    integerThan3600: 'Please enter an integer 1-3600',
    maxThanMin: 'the maximum bet must be greater than the minimum bet',
    minThanMax: 'minimum bet must be less than maximum bet',
    minimumBet: 'Please enter the minimum bet',
    maximumBet: 'Please enter the maximum bet',
    sureRemoveTable: 'Are you sure to remove the table?',
    notRecalc: 'Did not get the source data, can not be recalculated',
    errorNumber36: 'The entered number is incorrect. Please enter a number from 0 to 36.',
    errorNumber6: 'The number entered is incorrect. Please enter a number from 1 to 6.',
    unFinished: 'Unfinished changes, please confirm',
    unModify: 'Unmodified, cannot be recalculated',
    selectPermissions: 'please select permission!',
    enterAgainPassword: 'Please enter your password again',
    inconsistentTwice: 'Inconsistent input password twice!',
    expired: 'expired',
    inTheShow: 'in then show',
    notShown: 'not shown',
    enterRemark: 'Please enter remark',
    selectIpScope: 'Please select IP scope',
    enterCorrectIp: 'Please enter the correct ip',
    enterCorrectUrl: 'Please enter the correct url',
    tableIdNotData: 'The selected table is invalid, please re-select the jump table',
    selectFile: 'Please select a file',
    successfullyDeleted: 'successfully deleted',
    redLimitSelect: 'Jingmi and package table baccarat can only be configured with a set of red limit schemes',
    errorNoticeTitle: 'Does not support spaces or special symbols',
    selectGameType: 'Please select the type of game',
    dragToolTip: 'Mouse drag up and down to move the table order',
    notSelectRole: 'Please confirm the assignment of the corresponding account role',
    overThreeRole: 'No more than 3 characters',
    atLeastLabel: 'At least one game table',
    idSearchError: 'ID queries can only be numbers',
    h_systemmanagement_147: 'Only supports csv file format',
    defaultSetChipTips: 'please setting default chips',
    defaultCheckChipTips: 'please setting check chips',
    moduleName: '4-20 simplified Chinese characters',
    chineseName: '4-20 traditional characters',
    englishName: '4-20 English characters',
    vietnameseName: '4-20 Vietnamese characters',
    thaiName: '4-20 Thai characters',
    successTopTable: 'The table sequence number you set this time has taken effect'
  },
  title: {
    newGameTable: 'new game table',
    editGameTable: 'edit game table',
    gameTableDetails: 'game table details',
    editGameType: 'edit game type',
    gameTypeDetails: 'game type details',
    addAccount: 'add account',
    editAccount: 'edit account',
    recalculationBureau: 'recalculation game bureau',
    limitSelect: 'limit select'
  },
  page: {
    welcome: 'Welcome to login live video game management background',
    title: 'Game management',
    dragInfo: 'drag and drop allocation',
    dragSort: 'sort up and down',
    newPlan: 'new plan',
    editPlan: 'edit plan',
    setChip: 'set chip',
    limitNewInfo: 'The red limit of the gameplay will be automatically generated based on the minimum maximum bet and the game odds.',
    notSupport: 'This video is not supported by your browser'
  },
  navbar: {
    dashboard: 'Dashboard',
    github: 'Github',
    logOut: 'Log Out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'Live entertainment CMS',
    logIn: 'Login',
    username: 'Please input Username',
    password: 'Please enter Password',
    codeVerify: 'please enter verification code',
    verificationCode: 'Verification code',
    less6Password: 'Password cannot be less than 6 digits'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  tips: {
    delPaiTip: 'Are you sure you want to delete this card?',
    setDefaultSelectedChip: 'Please select one of the above chips as the default selected chip'
  }
};
exports.default = _default;