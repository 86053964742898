"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _fingerprintjs = _interopRequireDefault(require("fingerprintjs2"));

var fingerName = 'finger';
var _default = {
  load: function load() {
    _fingerprintjs.default.get(function (components) {
      var values = components.map(function (component) {
        return component.value;
      });

      var murmur = _fingerprintjs.default.x64hash128(values.join(''), 31);

      localStorage.setItem(fingerName, murmur);
    });
  },
  get: function get() {
    return localStorage.getItem(fingerName);
  }
};
exports.default = _default;