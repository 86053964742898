"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateParameterConfig = updateParameterConfig;
exports.addParameterConfig = addParameterConfig;
exports.getParameterConfigList = getParameterConfigList;
exports.getParameterConfigLog = getParameterConfigLog;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 参数配置 - 编辑
 *
 */
function updateParameterConfig(data) {
  return (0, _request.default)({
    url: '/gameManage/parameterConfig/update',
    method: 'post',
    data: data
  });
}
/**
 * 参数配置 - 新增
 *
 */


function addParameterConfig(data) {
  return (0, _request.default)({
    url: '/gameManage/parameterConfig/add',
    method: 'post',
    data: data
  });
}
/**
 * 参数配置 - 列表查询
 * @param {
 * code: string,
 * label: string,
 * page: number,
 * pageSize: number
 */


function getParameterConfigList(params) {
  return (0, _request.default)({
    url: '/gameManage/parameterConfig/list',
    method: 'get',
    params: params
  });
}
/**
 * 参数配置 - 操作记录查询
 * @param {
 * bizId: number,
 * userName: string,
 * ip: string,
 * startTime: string,
 * endTime: string,
 * page: number,
 * pageSize: number
 */


function getParameterConfigLog(params) {
  return (0, _request.default)({
    url: '/gameManage/parameterConfig/operationLogList',
    method: 'get',
    params: params
  });
}

var _default = {
  updateParameterConfig: updateParameterConfig,
  addParameterConfig: addParameterConfig,
  getParameterConfigList: getParameterConfigList,
  getParameterConfigLog: getParameterConfigLog
};
exports.default = _default;