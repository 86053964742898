"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gameModulePcList = gameModulePcList;
exports.gameModulePcSave = gameModulePcSave;
exports.gameModulePcUpdate = gameModulePcUpdate;
exports.gameModulePcUpdateSort = gameModulePcUpdateSort;
exports.gameModulePcUpdateStatus = gameModulePcUpdateStatus;
exports.gameModuleMobileList = gameModuleMobileList;
exports.gameModuleMobileSave = gameModuleMobileSave;
exports.gameModuleMobileUpdate = gameModuleMobileUpdate;
exports.gameModuleMobileUpdateSort = gameModuleMobileUpdateSort;
exports.gameModuleMobileUpdateStatus = gameModuleMobileUpdateStatus;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * pc大厅模块配置 - 获取Pc大厅模块配置
 */
function gameModulePcList(params) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigPc/list',
    method: 'get',
    params: params
  });
}
/**
 * pc大厅模块配置 - 保存Pc大厅模块配置
 */


function gameModulePcSave(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigPc/save',
    method: 'post',
    data: data
  });
}
/**
 * pc大厅模块配置 - 修改Pc大厅模块配置
 */


function gameModulePcUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigPc/update',
    method: 'post',
    data: data
  });
}
/**
 * pc大厅模块配置 - 修改Pc大厅模块配置排序
 */


function gameModulePcUpdateSort(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigPc/updateSort',
    method: 'post',
    data: data
  });
}
/**
 * pc大厅模块配置 - 修改Pc大厅模块配置状态
 */


function gameModulePcUpdateStatus(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigPc/updateStatus',
    method: 'post',
    data: data
  });
}
/**
 * 移动大厅模块配置 - 获取移动大厅模块配置
 */


function gameModuleMobileList(params) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigMobile/list',
    method: 'get',
    params: params
  });
}
/**
 * 移动大厅模块配置 - 保存移动大厅模块配置
 */


function gameModuleMobileSave(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigMobile/save',
    method: 'post',
    data: data
  });
}
/**
 * 移动大厅模块配置 - 修改移动大厅模块配置
 */


function gameModuleMobileUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigMobile/update',
    method: 'post',
    data: data
  });
}
/**
 * 移动大厅模块配置 - 修改移动大厅模块配置排序
 */


function gameModuleMobileUpdateSort(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigMobile/updateSort',
    method: 'post',
    data: data
  });
}
/**
 * 移动大厅模块配置 - 修改移动大厅模块配置状态
 */


function gameModuleMobileUpdateStatus(data) {
  return (0, _request.default)({
    url: '/gameManage/hallModuleConfigMobile/updateStatus',
    method: 'post',
    data: data
  });
}

var _default = {
  gameModulePcList: gameModulePcList,
  gameModulePcSave: gameModulePcSave,
  gameModulePcUpdate: gameModulePcUpdate,
  gameModulePcUpdateSort: gameModulePcUpdateSort,
  gameModulePcUpdateStatus: gameModulePcUpdateStatus,
  gameModuleMobileList: gameModuleMobileList,
  gameModuleMobileSave: gameModuleMobileSave,
  gameModuleMobileUpdate: gameModuleMobileUpdate,
  gameModuleMobileUpdateSort: gameModuleMobileUpdateSort,
  gameModuleMobileUpdateStatus: gameModuleMobileUpdateStatus
};
exports.default = _default;