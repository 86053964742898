"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    activePath: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        activePath = _context$props.activePath,
        path = _context$props.path;
    var vnodes = [];
    var active = activePath.includes(path);

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": active ? "".concat(icon, "_pre") : icon
        },
        "style": 'width: 24px;height: 25px;margin-right: 16px;'
      }));
    }
    /* eslint-disable */


    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title]));
    }
    /* eslint-disable */


    return vnodes;
  }
};
exports.default = _default;