"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

var _settings = _interopRequireDefault(require("@/settings"));

var _lang = _interopRequireDefault(require("@/utils/lang"));

var title = _settings.default.title || 'zr - game';

function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));

  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));

    return "".concat(pageName, " - ").concat(title);
  }

  return "".concat(title);
}