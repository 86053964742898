"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roleManageSearch = roleManageSearch;
exports.roleManageRoleDetail = roleManageRoleDetail;
exports.roleManagePermissions = roleManagePermissions;
exports.roleManageModifyStatus = roleManageModifyStatus;
exports.roleManageEditRole = roleManageEditRole;
exports.roleManageCreate = roleManageCreate;
exports.roleManageDelete = roleManageDelete;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 角色管理 - 查询
 * @param {
 *  startAt: string,
 * 	endAt: string,
 *  status: number,
 *  roleName: string,
 *  createBy: string,
 *  page: number,
 *  pageSize: number
 * }
 */
function roleManageSearch(params) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 角色管理 - 查询角色详细信息
 * @param {
 * id: number
 * }
 */


function roleManageRoleDetail(params) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/roleDetail',
    method: 'get',
    params: params
  });
}
/**
 * 角色管理 - 查询用户拥有的全部权限
 * @param {
 * id: number
 * }
 */


function roleManagePermissions(params) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/permissions',
    method: 'get',
    params: params
  });
}
/**
 * 角色管理 - 修改角色状态
 * @param {
 *   id: string,
 *   status: string
 * }
 */


function roleManageModifyStatus(data) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/modifyStatus',
    method: 'post',
    data: data
  });
}
/**
 * 角色管理 - 角色编辑
 * @param {
 *   id: string,
 *   permissionIds: [],
 *   remark: string,
 *   roleName: string
 * }
 */


function roleManageEditRole(data) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/editRole',
    method: 'post',
    data: data
  });
}
/**
 * 角色管理 - 角色新增
 * @param {
 *   permissionIds: [],
 *   remark: string,
 *   roleName: string
 * }
 */


function roleManageCreate(data) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 角色管理 - 删除角色
 * @param {
 *   "id": 0,
 *   "remark": "string"
 * }
 */


function roleManageDelete(data) {
  return (0, _request.default)({
    url: '/systemManage/roleManage/delete',
    method: 'post',
    data: data
  });
}

var _default = {
  roleManageSearch: roleManageSearch,
  roleManageRoleDetail: roleManageRoleDetail,
  roleManagePermissions: roleManagePermissions,
  roleManageModifyStatus: roleManageModifyStatus,
  roleManageEditRole: roleManageEditRole,
  roleManageCreate: roleManageCreate,
  roleManageDelete: roleManageDelete
};
exports.default = _default;