"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateGameTableStatus = updateGameTableStatus;
exports.createGameTable = createGameTable;
exports.roundManageSearch = roundManageSearch;
exports.roundManageAbnormaltRoundSubmit = roundManageAbnormaltRoundSubmit;
exports.cryptoCardMangeSearch = cryptoCardMangeSearch;
exports.getCardList = getCardList;
exports.checkCard = checkCard;
exports.thirdCheckList = thirdCheckList;
exports.thirdCheckUpdate = thirdCheckUpdate;
exports.gameResultExport = gameResultExport;
exports.abnormalExport = abnormalExport;
exports.getTypeGroupListInfo = getTypeGroupListInfo;
exports.batchSaveLimitTableInfo = batchSaveLimitTableInfo;
exports.selectLimitTableInfoList = selectLimitTableInfoList;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 启用停用
 * @param {
 * id:string,
 * operationType:number
 * } data
 */
function updateGameTableStatus(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/operation',
    method: 'post',
    data: data
  });
}
/**
 * 创建游戏桌台
 * @param {
 *  "gameTypeId 游戏类型ID ":"1001"
 *  "name 游戏名称" : "test数据桌子01"
 * "gameTypeId" : "2"
 * "physicsTableNo" : "10010"
 *  "dealerDataSources" : "https://www.baidu.com"
 * "videoUrl" : "https://www.baidu.com"
 * "seatBalanceLimit" : "10"
 *  "oneselfBalanceLimit" : "10"
 * "shareBalanceLimit" : "10"
 *  }
 */


function createGameTable(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 游戏结果 - 查询
 * @param {
 * 	gameTypeId: string
 * 	roundNo: string
 * 	startAt: date
 * 	endAt: date
 * 	page: number
 *  pageSize: number
 * }
 */


function roundManageSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/roundManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 游戏结果 - 异常提交
 * @param {
 * 	id: string
 * 	remark: string
 * }
 */


function roundManageAbnormaltRoundSubmit(data) {
  return (0, _request.default)({
    url: '/gameManage/roundManage/exceptionSubmit',
    method: 'post',
    data: data
  });
} // 区块链百家乐API - 查询所有牌


function cryptoCardMangeSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/cryptoCardMange/search',
    method: 'get',
    params: params
  });
} // 区块链百家乐API - 根据局号查询所有的卡牌


function getCardList(params) {
  return (0, _request.default)({
    url: '/gameManage/cryptoCardMange/getCardList',
    method: 'get',
    params: params
  });
} // 区块链百家乐API - 牌校验


function checkCard(params) {
  return (0, _request.default)({
    url: '/gameManage/cryptoCardMange/checkCard',
    method: 'get',
    params: params
  });
} // 查询第三方验证地址


function thirdCheckList(params) {
  return (0, _request.default)({
    url: '/gameManage/thirdCheck/list',
    method: 'get',
    params: params
  });
} // 更新第三方验证地址


function thirdCheckUpdate(params) {
  return (0, _request.default)({
    url: '/gameManage/thirdCheck/update',
    method: 'get',
    params: params
  });
} // 游戏结果导出


function gameResultExport(params) {
  return (0, _request.default)({
    // gameManage/roundManage/exportAsync /gameManage/roundManage/export
    url: '/gameManage/roundManage/exportAsync',
    method: 'get',
    responseType: 'blob',
    params: params
  });
} // 异常局导出


function abnormalExport(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/export',
    method: 'get',
    responseType: 'blob',
    params: params
  });
} // 单限红桌台列表返回


function getTypeGroupListInfo(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/getTypeGroupListInfo',
    method: 'get',
    params: params
  });
} // 批量保存限红桌台选中信息


function batchSaveLimitTableInfo(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/batchSaveLimitTableInfo',
    method: 'post',
    data: data
  });
} // 查询限红桌台是否选中列表


function selectLimitTableInfoList(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/selectLimitTableInfoList',
    method: 'get',
    params: params
  });
}

var _default = {
  roundManageSearch: roundManageSearch,
  roundManageAbnormaltRoundSubmit: roundManageAbnormaltRoundSubmit,
  cryptoCardMangeSearch: cryptoCardMangeSearch,
  getCardList: getCardList,
  checkCard: checkCard,
  thirdCheckList: thirdCheckList,
  thirdCheckUpdate: thirdCheckUpdate,
  abnormalExport: abnormalExport,
  gameResultExport: gameResultExport,
  getTypeGroupListInfo: getTypeGroupListInfo,
  batchSaveLimitTableInfo: batchSaveLimitTableInfo,
  selectLimitTableInfoList: selectLimitTableInfoList
};
exports.default = _default;