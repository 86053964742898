"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    asyncExcelBatchDownload: '批量下载',
    asyncExcelRepeat: '重复执行任务',
    asyncExcelBatchDelete: '批量删除',
    asyncExcel: '导出下载中心',
    asyncExcelPageList: '导出列表',
    gameresult_export: '游戏结果导出',
    page_betting_record_export: '记录导出',
    dashboard: '首页',
    gamemanagement: '游戏管理',
    gametype: '游戏类型',
    gametypedetail: '类型详情',
    gametypeedit: '类型编辑',
    gameplay: '游戏玩法',
    gameplaymodify: '玩法状态修改',
    gameplayedit: '玩法编辑',
    gameplaydetail: '玩法详情',
    gamedesk: '游戏桌台',
    getTenantPage: '租户管理',
    gamedesknew: '新建桌台',
    gamedeskedit: '编辑桌台',
    gamedeskmodify: '桌台状态修改',
    gametableopenstatusmodify: '开放状态修改',
    tablestartstop: '停启用状态修改',
    tableopen: '维护状态修改',
    batchtablelist: '批量操作',
    gamelimit: '游戏限红',
    gamelimitnew: '新建限红方案',
    gamelimitdetail: '限红方案详情',
    gamelimitedit: '编辑限红方案',
    limitProportion: '限红比例配置',
    gamepclabel: 'pc端大厅标签',
    gamepclabelnew: '新版pc端大厅标签',
    gamepclabeladjust: '调整桌台排序',
    gamepclabeldistribution: '分配桌台',
    gamepclabelmove: '移除桌台',
    gamemobilelabel: '移动端大厅标签',
    gamemobilelabelnew: '新版移动端大厅标签',
    gamemobilelabeladjust: '调整桌台排序',
    gamemobilelabeldistribution: '分配桌台',
    gamemobilelabelmove: '移除桌台',
    gamemodule: 'pc大厅模块配置',
    gamemodulenew: '新增模块',
    gamemoduleedit: '编辑模块',
    gamemodulesort: '模块排序',
    gamemodulestatus: '修改模块状态',
    gamemmodule: '移动大厅模块配置',
    gamemmodulenew: '新增模块',
    gamemmoduleedit: '编辑模块',
    gamemmodulesort: '模块排序',
    gamemmodulestatus: '修改模块状态',
    gameresult: '游戏结果',
    gameresultsubmit: '异常提交',
    gameexsituation: '异常局',
    gameexsituationcancel: '取消预览',
    gameexsituationrecalc: '重算预览',
    systemblockchainurl: '区块链第三方验证网址',
    systemblockchainencrypt: '区块链加密记录',
    systemblockchainurlupdate: '编辑',
    applyRound: '提交申请',
    update_result: '修改结果',
    detail: '明细查看',
    bet_record_detail: '明细注单',
    cancelRound: '撤销',
    systemmanagement: '系统管理',
    systemrole: '角色管理',
    systemrolenew: '新建角色',
    systemroleedit: '编辑角色',
    systemrolemodify: '角色状态修改',
    systemroledelete: '删除角色',
    systemaccount: '账号管理',
    systemaccountnew: '新建账号',
    systemaccountedit: '编辑账号',
    systemaccountmodify: '账号状态修改',
    systemaccountpassword: '密码修改',
    systemnotice: '公告管理',
    systemnoticenew: '新建公告',
    systemnoticeedit: '编辑公告',
    systemnoticemodify: '公告状态修改',
    systemipblacklist: 'IP黑名单',
    systemipblacklistnew: 'IP黑名单添加',
    systemipblacklistdelete: 'IP黑名单删除',
    maintenancesstatistic: '服务维护',
    maintenancestartstart: '区块链桌台恢复开新局',
    maintenancestartstop: '区块链桌台停止开新局',
    ipwhitelist: 'IP白名单',
    ipwhitelistsave: '添加白名单',
    ipwhitelistupdatestatus: '启用停用白名单',
    ipWhiteListUpdate: '编辑白名单',
    systemhandlelog: '操作记录',
    checkView: '查看',
    platformmaintain: '维护状态修改',
    parameterConfig: '参数配置',
    addParameterConfig: '新增参数配置',
    updateParameterConfig: '修改参数配置',
    parameterChangeRecord: '参数修改记录',
    seetingCurrency: '设置默认筹码',
    limitProportionEdit: '编辑',
    betLimitCurrency: '查看多币种限红',
    labelTopTable: '查看置顶桌台',
    labelTable: '查看未置顶桌台',
    updateLabelTable: '更新置顶桌台',
    mobilelabelTopTable: '查看置顶桌台',
    mobilelabelTable: '查看未置顶桌台',
    mobileupdateLabelTable: '更新置顶桌台',
    apiControl: 'API租户管理',
    推荐管理: '推荐管理'
  },
  tableColumn: {
    southIdName: '美洲厅关联ID',
    whetherToEnable: '是否启用',
    sort: '排序',
    no: '序号',
    tableName: '桌台名称',
    moduleName: '简体中文名称',
    chineseName: '繁体中文名称',
    englishName: '英文名称',
    vietnameseName: '越南文名称',
    thaiName: '泰文名称',
    tableId: '游戏桌台ID',
    gameType: '游戏类型',
    physicalTableNumber: '物理桌台号',
    videoAddress: '视频房间号',
    dealerSource: '荷官数据源',
    redLimit: '限红',
    minimumBet: '最小投注',
    minimumBetNumber: '最小投注额',
    maximumBet: '最大投注',
    maximumBetNumber: '最大投注额',
    odds: '赔率',
    redLimitScheme: '限红方案',
    redLimitSchemeName: '限红方案名称',
    schemeName: '方案名称',
    detailLimit: '具体限红',
    multiCurrencyLimit: '多币种限红',
    typeName: '类型名称',
    play: '玩法',
    betTime: '下注时间',
    waitingForCleaningTime: '等待清理时间',
    supportMultiple: '支持多台',
    automaticExitNumber: '自动退出局数',
    bureauNumber: '局号',
    bootNumber: '靴号',
    bootType: '靴类型',
    tableNumber: '桌台号',
    result: '结果',
    dealerAccount: '荷官账号',
    dealerName: '荷官名字',
    video: '录像',
    submitter: '提交人',
    submissionTime: '提交时间',
    submitRemark: '提交备注',
    gamePlay: '游戏玩法',
    gameName: '玩法名称',
    gameOdds: '玩法赔率',
    bootsLimit: '牌靴局数限制',
    description: '描述',
    ipSegment: '黑名单IP段',
    remark: '备注',
    currency: '币种',
    renminbi: '人民币',
    account: '账号',
    userName: '用户名',
    operationalContent: '操作内容',
    role: '角色',
    processingPerson: '处理人',
    processResult: '处理结果',
    processingTime: '处理时间',
    startingHandCardTime: '起手牌咪牌时间',
    cardTime56: '第5/6张牌咪牌时间',
    maximumCards: '最大连续飞牌局数',
    thenumberofboots: '允许更换牌靴局数',
    carrierFrequency: '允许更换荷官频率',
    noBetAutomaticExitTime: '不下注自动退出时间',
    autoOpenWhenTimeout: '不操作自动开牌时间',
    assignableRole: '可分配角色',
    assignedRole: '已分配角色',
    normal: '正常',
    shutDown: '关闭',
    status: '状态',
    operating: '操作',
    venueCurrency: '场馆货币',
    currencyCode: '币种编码',
    venueCurrencyOrGameCurrency: '场馆货币：游戏币',
    createTime: '创建时间',
    lastUpdateTime: '最后更新时间',
    editCurrency: '编辑币种'
  },
  btn: {
    tip: '提示',
    cancel: '取消',
    submit: '提交',
    confirm: '确认',
    ok: '确定',
    distributionTable: '分配桌台',
    topTable: '置顶桌台',
    distributionAllTable: '分配全部桌台',
    cancelAllTable: '取消全部桌台',
    distributionAllTableForTop: '置顶全部桌台',
    cancelAllTableForTop: '取消全部置顶',
    choice: '选择',
    search: '搜索',
    reset: '重置',
    new: '新增',
    edit: '编辑',
    detail: '详情',
    delete: '删除',
    remove: '移除',
    view: '查看',
    changeRecord: '修改记录',
    changePassword: '修改密码',
    createAccount: '创建账号',
    newAnnouncement: '新建公告',
    abnormalSubmission: '异常提交',
    exceptionHandling: '异常处理中',
    toBeProcessed: '待申请',
    processed: '已处理',
    creatingRole: '创建角色',
    editRole: '编辑角色',
    batchIpDownload: '批量IP添加导入模板下载',
    addBlacklist: '添加黑名单',
    batchAddBlacklist: '批量添加黑名单',
    batchImportBlacklist: '批量导入IP黑名单',
    cancelBureau: '取消局',
    recalculationBureau: '重算局',
    videoView: '录像查看',
    viewAll: '查看全部',
    create: '创建',
    notSet: '未设置',
    modify: '修改',
    seetingCurrency: '设置筹码'
  },
  label: {
    dianshu: '点数',
    beishu: '倍数',
    playera: '玩家A',
    playerb: '玩家B',
    gamePcFront: '游戏PC前端',
    gameH5Mobile: '游戏H5移动端',
    scrollingAnnouncement: '滚屏公告',
    popUpAnnouncement: '弹窗公告',
    name: '名称',
    password: '密码',
    newPassword: '新密码',
    comfirmPassword: '确认密码',
    minimumBet: '最小投注',
    oneTimeMinBet: '1倍赔率最小投注',
    maximumBet: '最大投注',
    oneTimeMaxBet: '1倍赔率最大投注',
    limitRange: '限红范围',
    idSearch: 'ID查询',
    tableStatus: '桌台状态',
    nameSearch: '名称查询',
    title: '标题',
    content: '内容',
    file: '文件',
    announcementType: '公告类型',
    showTime: '公告展示时间',
    hallLabelName: '大厅标签名字',
    unallocatedTable: '未分配桌台',
    assigned: '已分配至',
    comfirmClose: '确认关闭',
    bureauStatus: '局状态',
    bureauNumberInquiry: '局号查询',
    time: '起始时间',
    second: '秒',
    playStatus: '玩法状态',
    creationTime: '创建时间',
    updatedTime: '最近修改时间',
    releaseTime: '发布时间',
    announcing: '发布中',
    unpublished: '未发布',
    announcementStatus: '公告状态',
    operatingTime: '操作时间',
    operationType: '操作类型',
    operationAccount: '操作账号',
    roleStatus: '角色状态',
    roleName: '角色名称',
    roleDescription: '角色描述',
    permissionSettings: '权限设置',
    founder: '创建人',
    updatedBy: '最近配置修改人',
    accountStatus: '账号状态',
    userAccount: '用户账号',
    ipSegment: 'IP段',
    ipScope: 'IP作用域',
    startingIP: '起始IP',
    cutoffIP: '截止IP',
    oneselfBalanceLimit: '独享包桌额度条件',
    shareBalanceLimit: '共享包桌额度条件',
    seatBalanceLimit: '入座额度条件',
    seatMode: '入座模式',
    insuranceBetTime: '保险下注时间',
    pointsBetTime: '分牌下注时间',
    playerOutsTime: '闲家补牌时间',
    paid: '已派彩',
    jumped: '已跳局',
    recalculated: '已重算',
    originalResult: '原结果',
    player: '闲',
    banker: '庄',
    anda: '安达',
    baha: '巴哈',
    xiaochou: '小丑牌',
    xiaochouFlower: '小丑牌花色',
    xiaochouPoints: '小丑牌点数',
    common: '公共牌',
    di: '第',
    dragonFlower: '龙花色',
    tigerColor: '虎花色',
    dragonPoints: '龙点数',
    tigerPoints: '虎点数',
    cardSuit: '张牌花色',
    cardPoints: '张牌点数',
    label: '标签',
    dictionaryCode: '字典code',
    type: '类型',
    explain: '注释',
    value: '值',
    defaultSetChip: '默认设置筹码',
    defaultCheckChip: '默认选中筹码'
  },
  option: {
    all: '全部',
    selectAll: '全选',
    enabled: '启用',
    disable: '停用',
    yes: '是',
    no: '否'
  },
  placeholder: {
    noSpace: '名称不能包含空格',
    select: '请选择',
    within500Words: '500字以内',
    within300Words: '300字以内',
    within100Words: '100字以内',
    within50Words: '50字以内',
    within30Words: '30字以内',
    idSearch: '请输入桌台ID',
    tableStatus: '请选择桌台状态',
    nameSearch: '请输入桌台名称',
    enterIP: '请输入IP',
    lastWeek: '最近一周',
    lastMonth: '最近一个月',
    lastThreeMonths: '最近三个月',
    startTime: '开始日期',
    to: '至',
    endTime: '结束日期',
    startDate: '开始时间',
    endDate: '结束时间',
    enterNumber: '请输入局号',
    roleStatus: '请选择角色状态',
    roleName: '请输入角色名称',
    founder: '请输入创建人账号',
    comfirmPassword: '请输入确认密码',
    publishAccount: '请输入操作账号',
    exceptionDescription: '异常说明',
    enterNoticeTitle: '请输入公告标题',
    enterNoticeContent: '请输入公告内容',
    selectShowTime: '请选择展示时间',
    enterGameNumber: '请输入号码',
    lineLimit: '额度限制',
    enterLabel: '请输入标签名',
    enterDictionaryCode: '请输入字典code',
    moduleName: '请输入简体中文名称',
    chineseName: '请输入繁体中文名称',
    englishName: '请输入英文名称',
    vietnameseName: '请输入越南文名称',
    thaiName: '请输入泰文名称'
  },
  message: {
    andabahaError1: '安达的牌数只能比巴哈的牌数多一张，请更改再提交',
    andabahaError2: '有重复牌，请更改再提交',
    andabahaError3: '安达和巴哈的牌数只能一样，请更改再提交',
    andabahaError4: '必须有一张牌',
    andabahaError4_: '巴哈必须有一张牌',
    andabahaError5: '所有牌总数量不能>50',
    betCountDownError: '请输入下注时间',
    waitCountDownError: '请输入等待清理时间',
    rebootExitNumError: '请输入自动退出局数',
    pleaseEnterTime: '必填项,请输入时间',
    pleaseEnterNumber: '必填项,请输入数字',
    pleaseEnter: '请输入',
    enterUserName: '请输入用户名',
    warning: '警告',
    delete: '是否确认删除',
    deleteCol: '请选择需要删除列',
    successfulOperation: '操作成功',
    errorNoToTopBefore: '不能排到置顶桌台之前',
    submittedSuccessfully: '提交成功',
    exceptionDescription: '请填写异常说明',
    afterTable: '该桌台',
    enabledTable: '是否确认启用该桌台?',
    disableTable: '是否确认停用该桌台?',
    enabledPlay: '是否确认启用该玩法?',
    afterPlay: '该玩法',
    disablePlay: '是否确认停用该玩法?',
    frontRole: '是否确认',
    afterRole: '该角色授权',
    openRole: '启用',
    closeRole: '停用',
    removeRole: '删除',
    deleteRole: '是否确认删除该角色授权?',
    enabledRole: '是否确认启用该角色授权',
    disableRole: '是否确认停用该角色授权',
    afterAccount: '该账号',
    afterModule: '该模块',
    enabledAccount: '是否确认恢复该账号?',
    disableAccount: '是否确认停用该账号?',
    enabledNotice: '是否确认启用该公告内容',
    disableNotice: '是否确认停用该公告内容',
    enterTableName: '请填写桌台名称',
    enterTableNo: '请填写物理桌台号',
    enterDelearData: '请填写荷官数据源',
    enterVideoUrl: '请填写视频地址',
    selectLimit: '请选择限红方案',
    oneselfBalanceLimit: '请填写独享包桌额度条件',
    shareBalanceLimit: '请填写共享包桌额度条件',
    seatBalanceLimit: '请填写入座额度条件',
    enterRoleDescription: '请输入角色描述',
    createdSuccessfully: '创建成功',
    newCreateSuccess: '新建成功',
    editSuccess: '编辑成功',
    cancelSuccess: '取消成功',
    cancelGame: '取消游戏局后会立刻生效，退还所有投注，是否确认取消游戏局?',
    cancelled: '已取消',
    selectNoticeType: '请选择公告类型',
    deskValidName: '4~20个字符,中文/数字/英文',
    deskValidName2: '4~12个字符,中文/数字/英文',
    validName6: '2-6字符,中文/数字/英文/越南文/泰文',
    validName20: '1-20字符,中文/数字/英文/越南文/泰文/韩文/日文',
    roleValidName: '1~15个字符,中文/数字/英文',
    deskValidPhysicsTableNo: '3个字符,数字/英文',
    deskValidNumber: '1~12个数字,整数',
    validNumberEn12: '6~12个字符,数字/英文',
    validNumberEnCn: '2-6字符,中文/数字/英文',
    resultValidName: '2~60个字符,中文/数字/英文',
    integerThan0: '请输入大于0的整数',
    integerThan100: '请输入1-100的整数',
    integerThan1000: '请输入1-1000的整数',
    integerThan3600: '请输入1-3600的整数',
    maxThanMin: '最大投注必须大于最小投注',
    minThanMax: '最小投注必须小于最大投注',
    minimumBet: '请输入最小投注',
    maximumBet: '请输入最大投注',
    sureRemoveTable: '确认移除该桌台吗?',
    notRecalc: '没获取到源数据,不能重算',
    errorNumber36: '输入的号码不正确，请输入0~36范围内的数字',
    errorNumber6: '输入的号码不正确，请输入1~6范围内的数字',
    unFinished: '未完成修改，请确认',
    unModify: '未作修改，无法重算',
    selectPermissions: '请选择权限!',
    enterAgainPassword: '请再次输入密码',
    inconsistentTwice: '两次输入密码不一致!',
    expired: '已过期',
    inTheShow: '展示中',
    notShown: '未展示',
    enterRemark: '请输入备注',
    selectIpScope: '请选择IP作用域',
    enterCorrectIp: '请输入正确的ip',
    enterCorrectUrl: '请输入正确URL地址',
    tableIdNotData: '选定的桌台无效,请重新选择跳转桌台',
    selectFile: '请选择文件',
    successfullyDeleted: '删除成功',
    redLimitSelect: '竞咪和包桌百家乐只能配置一套限红方案',
    errorNoticeTitle: '不支持空格或特殊符号',
    selectGameType: '请选择游戏类型',
    dragToolTip: '鼠标拖动上下移动表格顺序',
    notSelectRole: '请确认分配对应账号角色',
    overThreeRole: '最多不超过3个角色',
    atLeastLabel: '最少分配一个游戏桌台',
    idSearchError: 'ID查询只能为数字',
    h_systemmanagement_147: '仅支持csv文件格式',
    defaultSetChipTips: '请设置默认设置筹码',
    defaultCheckChipTips: '请设置默认选中筹码',
    moduleName: '4-20个简体中文字符',
    chineseName: '4-20个繁体字符',
    englishName: '4-20个英文字符',
    vietnameseName: '4-20个越南文字符',
    thaiName: '4-20个泰文字符',
    successTopTable: '您本次设置的桌台排序序号已生效'
  },
  download: {
    tit: '下载中心',
    batchDown: '批量下载',
    taskSee: '查看导出任务',
    downTip: '导出任务创建成功，请到下载中心查看！',
    refresh: '刷新',
    down: '下载',
    taskTit: '任务名称',
    reExport: '重新导出',
    downFail: '导出失败',
    downSuc: '导出成功',
    downing: '正在导出',
    downDoc: '下载文档',
    delTask: '删除任务',
    taskDetail: '任务清单',
    batchDel: '批量删除',
    noData: '暂无数据',
    no: '序号',
    status: '状态',
    operator: '操作',
    createTime: '创建时间',
    creator: '创建人'
  },
  title: {
    newGameTable: '新增游戏桌台',
    editGameTable: '编辑游戏桌台',
    gameTableDetails: '游戏桌台详情',
    editGameType: '编辑游戏类型',
    gameTypeDetails: '游戏类型详情',
    addAccount: '新增账号',
    editAccount: '编辑账号',
    recalculationBureau: '重算游戏局',
    limitSelect: '限红选择'
  },
  page: {
    welcome: '欢迎登录真人视讯游戏管理后台',
    title: '游戏管理后台',
    dragInfo: '可左右拖拽分配',
    dragSort: '可上下拖拽排序',
    newPlan: '创建方案',
    editPlan: '编辑方案',
    setChip: '设置筹码',
    limitNewInfo: '玩法的限红将根据最小最大投注以及玩法赔率自动生成',
    notSupport: '您的浏览器不支持此视频'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出账号',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '游戏管理后台',
    logIn: '登录',
    username: '请输入账号',
    password: '请输入密码',
    codeVerify: '请输入谷歌验证码',
    verificationCode: '验证码',
    less6Password: '密码不能少于6位'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  tips: {
    delPaiTip: '你确定删除此牌吗?',
    setDefaultSelectedChip: '请从以上筹码中选择1个作为默认选中筹码'
  }
};
exports.default = _default;