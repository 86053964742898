"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.limitRedManageDetails = limitRedManageDetails;
exports.getGameTypeBetLimit = getGameTypeBetLimit;
exports.getCurrencyBetLimit = getCurrencyBetLimit;
exports.limitRedManageEdit = limitRedManageEdit;
exports.limitRedManageSave = limitRedManageSave;
exports.limitRedManageSearch = limitRedManageSearch;
exports.searchDoubleLimit = searchDoubleLimit;
exports.limitRedManageUpdate = limitRedManageUpdate;
exports.limitSettingCurrencyUpdate = limitSettingCurrencyUpdate;
exports.getLimitProportion = getLimitProportion;
exports.updataLimitProportion = updataLimitProportion;
exports.doubleLimitGroup = doubleLimitGroup;
exports.batchSaveTwoLimitTable = batchSaveTwoLimitTable;
exports.betLimitDoubleSubmit = betLimitDoubleSubmit;
exports.betLimitDoubleGetList = betLimitDoubleGetList;
exports.betLimitDoubleDelete = betLimitDoubleDelete;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 限红管理 - 详情
 * @param {
 * id: number
 * }
 */
function limitRedManageDetails(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/getDetail',
    method: 'get',
    params: params
  });
}

function getGameTypeBetLimit(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/getGameTypeBetLimit',
    method: 'get',
    params: params
  });
}

function getCurrencyBetLimit(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/getCurrencyBetLimit',
    method: 'get',
    params: params
  });
}
/**
 * 限红管理 - 编辑
 * @param {
 *   id: number
 * }
 */


function limitRedManageEdit(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/edit',
    method: 'post',
    data: data
  });
}
/**
 * 限红管理 - 新建
 * @param {
 * "betLimitname": "string",
 * "max": 0,
 * "min": 0
 * }
 */


function limitRedManageSave(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/save',
    method: 'post',
    data: data
  });
}
/**
 * 限红管理 - 查询
 * @param {
 *  page: number,
 * 	pageSize: number
 * }
 */


function limitRedManageSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 限红管理 - 双查询
 * @param {
 *  page: number,
 * 	pageSize: number
 * }
 */


function searchDoubleLimit(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/searchDoubleLimit',
    method: 'get',
    params: params
  });
}
/**
 * 限红管理 - 修改
 * @param {
 * "id": 0,
 * "betLimitId": 0,
 * "betLimitname": "string",
 * "max": 0,
 * "min": 0
 * }
 */


function limitRedManageUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/update',
    method: 'post',
    data: data
  });
} // 修改多币种筹码


function limitSettingCurrencyUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/updataChip',
    method: 'post',
    data: data
  });
} // 查询限红比例配置


function getLimitProportion() {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/getLimitProportion',
    method: 'get'
  });
} // 修改限红比例配置


function updataLimitProportion(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/updataLimitProportion',
    method: 'post',
    data: data
  });
}
/**
 *
 *  双限红需求
 * *
 * */


function doubleLimitGroup() {
  return (0, _request.default)({
    url: '/gameManage/gameTableManage/getTypeGroupListInfoNew',
    method: 'get'
  });
}

function batchSaveTwoLimitTable(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitManage/batchSaveTwoLimitTable',
    method: 'post',
    data: data
  });
}

function betLimitDoubleSubmit(data) {
  return (0, _request.default)({
    url: '/gameManage/betLimitDouble/submit',
    method: 'post',
    data: data
  });
}

function betLimitDoubleGetList() {
  return (0, _request.default)({
    url: '/gameManage/betLimitDouble/getList',
    method: 'get'
  });
}

function betLimitDoubleDelete(params) {
  return (0, _request.default)({
    url: '/gameManage/betLimitDouble/deleteById',
    method: 'get',
    params: params
  });
}

var _default = {
  limitRedManageDetails: limitRedManageDetails,
  limitRedManageEdit: limitRedManageEdit,
  searchDoubleLimit: searchDoubleLimit,
  limitRedManageSave: limitRedManageSave,
  limitRedManageSearch: limitRedManageSearch,
  limitRedManageUpdate: limitRedManageUpdate,
  getGameTypeBetLimit: getGameTypeBetLimit,
  getCurrencyBetLimit: getCurrencyBetLimit,
  getLimitProportion: getLimitProportion,
  updataLimitProportion: updataLimitProportion,
  limitSettingCurrencyUpdate: limitSettingCurrencyUpdate,
  betLimitDoubleDelete: betLimitDoubleDelete,
  betLimitDoubleGetList: betLimitDoubleGetList,
  betLimitDoubleSubmit: betLimitDoubleSubmit,
  doubleLimitGroup: doubleLimitGroup,
  batchSaveTwoLimitTable: batchSaveTwoLimitTable
};
exports.default = _default;