"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gameTypeManageUpdate = gameTypeManageUpdate;
exports.gameTypeManageFind = gameTypeManageFind;
exports.gameTypeManageSearch = gameTypeManageSearch;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 游戏类型 - 编辑保存
 * @param {
 *	"allowChangeBootNum": 0,
 *  "betCountDown": 0,
 *  "gameTypeCode": "string",
 *  "lastMiSeconds": 0,
 *  "many": 0,
 *  "maxFinesseRoundNum": 0,
 *  "name": "string",
 *  "notBetOutAT": 0,
 *  "rebootExitNum": 0,
 *  "status": 0,
 *  "type": 0,
 *  "waitCountDown": 0
 }
*/
function gameTypeManageUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/gameTypeManage/update',
    method: 'post',
    data: data
  });
}
/**
 * 游戏类型 - 查看玩法
 * @param {
 *  id: number
 * }
 */


function gameTypeManageFind(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTypeManage/find',
    method: 'get',
    params: params
  });
}
/**
 * 游戏类型 - 列表查询
 * @param {
 * id: number,
 * name: string,
 * page: number,
 * pageSize: number
 */


function gameTypeManageSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/gameTypeManage/search',
    method: 'get',
    params: params
  });
}

var _default = {
  gameTypeManageUpdate: gameTypeManageUpdate,
  gameTypeManageFind: gameTypeManageFind,
  gameTypeManageSearch: gameTypeManageSearch
};
exports.default = _default;