"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _limitRed = require("@/api/limitRed");

var state = {
  list: [],
  currentPage: 0,
  total: 0
};
var mutations = {
  GET_LIST: function GET_LIST(state, data) {
    state.list = data.records;
    state.currentPage = data.pages;
    state.total = data.total;
  }
};
var actions = {
  getList: function getList(_ref, params) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _limitRed.limitRedManageSearch)(params).then(function (res) {
        var data = res.data;
        commit('GET_LIST', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;