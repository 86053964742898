"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre3-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.logout = logout;
exports.getVerifyCodeTypeandErrorLogin = getVerifyCodeTypeandErrorLogin;
exports.moonShieldAuth = moonShieldAuth;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}

function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'post'
  });
}

function getVerifyCodeTypeandErrorLogin(params) {
  return (0, _request.default)({
    url: '/getVerifyCodeTypeandErrorLogin',
    method: 'get',
    params: params
  });
}

function moonShieldAuth(data) {
  return (0, _request.default)({
    url: '/moonShieldAuthCheck',
    method: 'post',
    data: data
  });
}

var _default = {
  login: login,
  getInfo: getInfo,
  logout: logout,
  getVerifyCodeTypeandErrorLogin: getVerifyCodeTypeandErrorLogin,
  moonShieldAuth: moonShieldAuth
};
exports.default = _default;