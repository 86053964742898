"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  route: {
    dashboard: '首頁',
    gamemanagement: '遊戲管理',
    gametype: '遊戲類型',
    gametypedetail: '類型詳情',
    gametypeedit: '類型編輯',
    gameplay: '遊戲玩法',
    gameplaymodify: '玩法狀態修改',
    gameplayedit: '玩法編輯',
    gameplaydetail: '玩法詳情',
    gamedesk: '遊戲桌臺',
    gamedesknew: '新建桌臺',
    gamedeskedit: '編輯桌臺',
    gamedeskmodify: '桌臺狀態修改',
    gametableopenstatusmodify: '開放狀態修改',
    gamelimit: '遊戲限紅',
    gamelimitnew: '新建限紅方案',
    gamelimitdetail: '限紅方案詳情',
    gamelimitedit: '編輯限紅方案',
    limitProportion: '限紅比例配置',
    gamepclabel: 'pc端大廳標簽',
    gamepclabeladjust: '調整桌臺排序',
    gamepclabeldistribution: '分配桌臺',
    gamemobilelabel: '移動端大廳標簽',
    gamemobilelabeladjust: '調整桌臺排序',
    gamemobilelabeldistribution: '分配桌臺',
    gamemodule: '大廳模塊配置',
    gamemodulenew: '新增模塊',
    gamemoduleedit: '編輯模塊',
    gamemodulesort: '模塊排序',
    gamemodulestatus: '修改模塊狀態',
    gamemmodule: '移動大廳模塊配置',
    gamemmodulenew: '新增模塊',
    gamemmoduleedit: '編輯模塊',
    gamemmodulesort: '模塊排序',
    gamemmodulestatus: '修改模塊狀態',
    gameresult: '遊戲結果',
    gameresultsubmit: '異常提交',
    gameexsituation: '異常局',
    gameexsituationcancel: '取消局',
    gameexsituationrecalc: '重算局',
    systemmanagement: '系統管理',
    systemrole: '角色管理',
    systemrolenew: '新建角色',
    systemroleedit: '編輯角色',
    systemrolemodify: '角色狀態修改',
    systemroledelete: '刪除角色',
    systemaccount: '賬號管理',
    systemaccountnew: '新建賬號',
    systemaccountedit: '編輯賬號',
    systemaccountmodify: '賬號狀態修改',
    systemaccountpassword: '密碼修改',
    systemnotice: '公告管理',
    systemnoticenew: '新建公告',
    systemnoticeedit: '編輯公告',
    systemnoticemodify: '公告狀態修改',
    systemipblacklist: 'IP黑名單',
    systemipblacklistnew: 'IP黑名單添加',
    systemipblacklistdelete: 'IP黑名單刪除',
    systemhandlelog: '操作記錄',
    checkView: '查看',
    parameterConfig: '參數配置',
    addParameterConfig: '新增參數配置',
    updateParameterConfig: '修改參數配置',
    parameterChangeRecord: '參數修改記錄',
    betLimitCurrency: '查看多幣種限紅',
    labelTopTable: '查看置頂桌台',
    labelTable: '查看未置頂桌台',
    updateLabelTable: '更新置頂桌台',
    mobilelabelTopTable: '查看置頂桌台',
    mobilelabelTable: '查看未置頂桌台',
    mobileupdateLabelTable: '更新置頂桌台'
  },
  tableColumn: {
    southIdName: '美洲廳關聯ID',
    whetherToEnable: '是否啟用',
    sort: '排序',
    no: '序號',
    tableName: '桌臺名稱',
    moduleName: '簡體中文名字',
    chineseName: '繁體中文名字',
    englishName: '英文名字',
    vietnameseName: '越南語名字',
    thaiName: '泰語名字',
    tableId: '遊戲桌臺ID',
    gameType: '遊戲類型',
    physicalTableNumber: '物理桌臺號',
    videoAddress: '視頻房間號',
    dealerSource: '荷官數據源',
    redLimit: '限紅',
    minimumBet: '最小投註',
    minimumBetNumber: '最小投註額',
    maximumBet: '最大投註',
    maximumBetNumber: '最大投註額',
    odds: '賠率',
    redLimitScheme: '限紅方案',
    redLimitSchemeName: '限紅方案名稱',
    schemeName: '方案名稱',
    detailLimit: '具體限紅',
    multiCurrencyLimit: '多幣種限紅',
    typeName: '類型名稱',
    play: '玩法',
    betTime: '下註時間',
    waitingForCleaningTime: '等待清理時間',
    supportMultiple: '支持多臺',
    automaticExitNumber: '自動退出局數',
    bureauNumber: '局號',
    bootNumber: '靴號',
    tableNumber: '桌臺號',
    result: '結果',
    dealerAccount: '荷官賬號',
    dealerName: '荷官名字',
    video: '錄像',
    submitter: '提交人',
    submissionTime: '提交時間',
    submitRemark: '提交備註',
    gamePlay: '遊戲玩法',
    gameName: '玩法名稱',
    gameOdds: '玩法賠率',
    bootsLimit: '牌靴局數限制',
    description: '描述',
    ipSegment: '黑名單IP段',
    remark: '備註',
    currency: '幣種',
    renminbi: '人民幣',
    account: '賬號',
    userName: '用戶名',
    operationalContent: '操作內容',
    role: '角色',
    processingPerson: '處理人',
    processResult: '處理結果',
    processingTime: '處理時間',
    startingHandCardTime: '起手牌咪牌時間',
    cardTime56: '第5/6張牌咪牌時間',
    maximumCards: '最大連續飛牌局數',
    thenumberofboots: '允許更換牌靴局數',
    carrierFrequency: '允許更換荷官頻率',
    noBetAutomaticExitTime: '不下註自動退出時間',
    autoOpenWhenTimeout: '不操作自動開牌時間',
    assignableRole: '可分配角色',
    assignedRole: '已分配角色',
    normal: '正常',
    shutDown: '關閉',
    status: '狀態',
    operating: '操作',
    venueCurrency: '場館貨幣',
    currencyCode: '幣種編碼',
    venueCurrencyOrGameCurrency: '場館貨幣：遊戲幣',
    createTime: '創建時間',
    lastUpdateTime: '最後更新時間',
    editCurrency: '編輯幣種'
  },
  btn: {
    cancel: '取消',
    submit: '提交',
    confirm: '確認',
    ok: '確定',
    distributionTable: '分配桌臺',
    distributionAllTable: '分配全部桌臺',
    cancelAllTable: '取消全部桌臺',
    distributionAllTableForTop: '置頂全部桌台',
    cancelAllTableForTop: '取消全部置頂',
    choice: '選擇',
    search: '搜索',
    reset: '重置',
    new: '新增',
    edit: '編輯',
    detail: '詳情',
    delete: '刪除',
    remove: '移除',
    view: '查看',
    changeRecord: '修改記錄',
    changePassword: '修改密碼',
    createAccount: '創建賬號',
    newAnnouncement: '新建公告',
    abnormalSubmission: '異常提交',
    exceptionHandling: '異常處理中',
    toBeProcessed: '待處理',
    processed: '已處理',
    creatingRole: '創建角色',
    editRole: '編輯角色',
    batchIpDownload: '批量IP添加導入模板下載',
    addBlacklist: '添加黑名單',
    batchAddBlacklist: '批量添加黑名單',
    batchImportBlacklist: '批量導入IP黑名單',
    cancelBureau: '取消局',
    recalculationBureau: '重算局',
    videoView: '錄像查看',
    viewAll: '查看全部',
    create: '創建',
    notSet: '未设置',
    modify: '修改'
  },
  label: {
    playera: '玩家A',
    playerb: '玩家B',
    gamePcFront: '遊戲PC前端',
    gameH5Mobile: '遊戲H5移動端',
    scrollingAnnouncement: '滾屏公告',
    popUpAnnouncement: '彈窗公告',
    name: '名稱',
    password: '密碼',
    newPassword: '新密碼',
    comfirmPassword: '確認密碼',
    minimumBet: '最小投註',
    oneTimeMinBet: '1倍賠率最小投註',
    maximumBet: '最大投注',
    oneTimeMaxBet: '1倍賠率最大投註',
    limitRange: '限紅範圍',
    idSearch: 'ID查詢',
    tableStatus: '桌臺狀態',
    nameSearch: '名稱查詢',
    title: '標題',
    content: '內容',
    file: '文件',
    announcementType: '公告類型',
    showTime: '展示時間',
    hallLabelName: '大廳標簽名字',
    unallocatedTable: '未分配桌臺',
    assigned: '已分配至',
    comfirmClose: '確認關閉',
    bureauStatus: '局狀態',
    bureauNumberInquiry: '局號查詢',
    time: '起始時間',
    second: '秒',
    playStatus: '玩法狀態',
    creationTime: '創建時間',
    updatedTime: '最近修改時間',
    releaseTime: '發布時間',
    announcing: '發布中',
    unpublished: '未發布',
    announcementStatus: '公告狀態',
    operatingTime: '操作時間',
    operationType: '操作類型',
    operationAccount: '操作賬號',
    roleStatus: '角色狀態',
    roleName: '角色名稱',
    roleDescription: '角色描述',
    permissionSettings: '權限設置',
    founder: '創建人',
    updatedBy: '最近配置修改人',
    accountStatus: '賬號狀態',
    userAccount: '用戶賬號',
    ipSegment: 'IP段',
    ipScope: 'IP作用域',
    startingIP: '起始IP',
    cutoffIP: '截止IP',
    oneselfBalanceLimit: '獨享包桌額度條件',
    shareBalanceLimit: '共享包桌額度條件',
    seatBalanceLimit: '入座額度條件',
    seatMode: '入座模式',
    insuranceBetTime: '保險下註時間',
    pointsBetTime: '分牌下註時間',
    playerOutsTime: '閑家補牌時間',
    paid: '已派彩',
    jumped: '已跳局',
    recalculated: '已重算',
    originalResult: '原結果',
    player: '閑',
    banker: '莊',
    anda: '安达',
    baha: '巴哈',
    xiaochou: '小丑牌',
    xiaochouFlower: '小丑牌花色',
    xiaochouPoints: '小丑牌點數',
    common: '公共牌',
    di: '第',
    dragonFlower: '龍花色',
    tigerColor: '虎花色',
    dragonPoints: '龍點數',
    tigerPoints: '虎點數',
    cardSuit: '張牌花色',
    cardPoints: '張牌點數',
    label: '標簽',
    dictionaryCode: '字典code',
    type: '類型',
    explain: '注釋',
    value: '值',
    defaultSetChip: '默认设置筹码',
    defaultCheckChip: '默认选中筹码'
  },
  option: {
    all: '全部',
    selectAll: '全選',
    enabled: '啟用',
    disable: '停用',
    yes: '是',
    no: '否'
  },
  placeholder: {
    noSpace: '名稱不能包含空格',
    select: '請選擇',
    within500Words: '500字以內',
    within300Words: '300字以內',
    within100Words: '100字以內',
    within50Words: '50字以內',
    within30Words: '30字以内',
    idSearch: '請輸入桌臺ID',
    tableStatus: '請選擇桌臺狀態',
    nameSearch: '請輸入桌臺名稱',
    enterIP: '請輸入IP',
    lastWeek: '最近壹周',
    lastMonth: '最近壹個月',
    lastThreeMonths: '最近三個月',
    startTime: '開始日期',
    to: '至',
    endTime: '結束日期',
    startDate: '開始時間',
    endDate: '結束時間',
    enterNumber: '請輸入局號',
    roleStatus: '請選擇角色狀態',
    roleName: '請輸入角色名稱',
    founder: '請輸入創建人賬號',
    comfirmPassword: '請輸入確認密碼',
    publishAccount: '請輸入操作賬號',
    exceptionDescription: '異常說明',
    enterNoticeTitle: '請輸入公告標題',
    enterNoticeContent: '請輸入公告內容',
    selectShowTime: '請選擇展示時間',
    enterGameNumber: '請輸入號碼',
    lineLimit: '額度限制',
    enterLabel: '請輸入標簽名',
    enterDictionaryCode: '請輸入字典code',
    moduleName: '4-20個簡體中文字符',
    chineseName: '4-20個繁體字符',
    englishName: '4-20個英文字符',
    vietnameseName: '4-20個越南文字符',
    thaiName: '4-20個泰文字符'
  },
  message: {
    andabahaError1: '安達的牌數只能比巴哈的牌數多一張，請更改再提交',
    andabahaError2: '有重複牌，請更改再提交',
    andabahaError3: '安達和巴哈的牌數只能一樣，請更改再提交',
    andabahaError4: '必須有一張牌',
    andabahaError4_: '巴哈必須有一張牌',
    andabahaError5: '所有牌總數量不能>50',
    betCountDownError: '請輸入下注時間',
    waitCountDownError: '請輸入等待清理時間',
    rebootExitNumError: '請輸入自動退出局數',
    pleaseEnterTime: '必填項,請輸入時間',
    pleaseEnterNumber: '必填項,請輸入數字',
    pleaseEnter: '請輸入',
    enterUserName: '請輸入用戶名',
    warning: '警告',
    delete: '是否確認刪除',
    deleteCol: '請選擇需要刪除列',
    successfulOperation: '操作成功',
    errorNoToTopBefore: '不能排到置頂桌台之前',
    submittedSuccessfully: '提交成功',
    exceptionDescription: '請填寫異常說明',
    afterTable: '該桌台',
    enabledTable: '是否確認啟用該桌臺?',
    disableTable: '是否確認停用該桌臺?',
    enabledPlay: '是否確認啟用該玩法?',
    afterPlay: '該玩法',
    disablePlay: '是否確認停用該玩法?',
    frontRole: '是否確認',
    afterRole: '該角色授權',
    openRole: '啟用',
    closeRole: '停用',
    removeRole: '刪除',
    deleteRole: '是否確認刪除該角色授權?',
    enabledRole: '是否確認啟用該角色授權?',
    disableRole: '是否確認停用該角色授權?',
    afterAccount: '該賬號',
    afterModule: '該模塊',
    enabledAccount: '是否確認恢復該賬號?',
    disableAccount: '是否確認停用該賬號?',
    enabledNotice: '是否確認啟用該公告內容',
    disableNotice: '是否確認停用該公告內容',
    enterTableName: '請填寫桌臺名稱',
    enterTableNo: '請填寫物理桌臺號',
    enterDelearData: '請填寫荷官數據源',
    enterVideoUrl: '請填寫視頻地址',
    selectLimit: '請選擇限紅方案',
    oneselfBalanceLimit: '請填寫獨享包桌額度條件',
    shareBalanceLimit: '請填寫共享包桌額度條件',
    seatBalanceLimit: '請填寫入座額度條件',
    enterRoleDescription: '請輸入角色描述',
    createdSuccessfully: '創建成功',
    newCreateSuccess: '新建成功',
    editSuccess: '編輯成功',
    cancelSuccess: '取消成功',
    cancelGame: '取消遊戲局後會立刻生效，退還所有投註，是否確認取消遊戲局?',
    cancelled: '已取消',
    selectNoticeType: '請選擇公告類型',
    deskValidName: '4~20個字符,中文/數字/英文',
    deskValidName2: '4~12個字符,中文/數字/英文',
    roleValidName: '1~15個字符,中文/數字/英文',
    validName6: '2-6字符,中文/數字/英文/越南文/泰文/韩文',
    validName20: '1-20字符,中文/數字/英文/越南文/泰文/韩文',
    deskValidPhysicsTableNo: '3個字符,數字/英文',
    deskValidNumber: '1~12個數字,整數',
    validNumberEn12: '6~12個字符,數字/英文',
    validNumberEnCn: '2-6字符,中文/數字/英文',
    resultValidName: '2~60個字符,中文/數字/英文',
    integerThan0: '請輸入大於0的整數',
    integerThan100: '請輸入1-100的整數',
    integerThan1000: '請輸入1-1000的整數',
    integerThan3600: '請輸入1-3600的整數',
    maxThanMin: '最大投註必須大於最小投註',
    minThanMax: '最小投註必須小於最大投註',
    minimumBet: '請輸入最小投註',
    maximumBet: '請輸入最大投註',
    sureRemoveTable: '確認移除該桌臺嗎?',
    notRecalc: '沒獲取到源數據,不能重算',
    errorNumber36: '輸入的號碼不正確，請輸入0~36範圍內的數字',
    errorNumber6: '輸入的號碼不正確，請輸入1~6範圍內的數字',
    unFinished: '未完成修改，請確認',
    unModify: '未作修改，無法重算',
    selectPermissions: '請選擇權限!',
    enterAgainPassword: '請再次輸入密碼',
    inconsistentTwice: '兩次輸入密碼不壹致!',
    expired: '已過期',
    inTheShow: '展示中',
    notShown: '未展示',
    enterRemark: '請輸入備註',
    selectIpScope: '請選擇IP作用域',
    enterCorrectIp: '請輸入正確的ip',
    enterCorrectUrl: '請輸入正確的url',
    tableIdNotData: '選定的桌台無效,請重新選擇跳轉桌台',
    selectFile: '請選擇文件',
    successfullyDeleted: '刪除成功',
    redLimitSelect: '競咪和包桌百家樂只能配置壹套限紅方案',
    errorNoticeTitle: '不支持空格或特殊符號',
    selectGameType: '請選擇遊戲類型',
    dragToolTip: '鼠標拖動上下移動表格順序',
    notSelectRole: '請確認分配對應賬號角色',
    overThreeRole: '最多不超過3個角色',
    atLeastLabel: '最少分配壹個遊戲桌臺',
    idSearchError: 'ID查詢只能為數字',
    h_systemmanagement_147: '僅支持csv文件格式',
    defaultSetChipTips: '請设置默认设置筹码',
    defaultCheckChipTips: '請设置默认选中筹码',
    successTopTable: '您本次設置的桌台排序序號已生效'
  },
  title: {
    newGameTable: '新增遊戲桌臺',
    editGameTable: '編輯遊戲桌臺',
    gameTableDetails: '遊戲桌臺詳情',
    editGameType: '編輯遊戲類型',
    gameTypeDetails: '遊戲類型詳情',
    addAccount: '新增賬號',
    editAccount: '編輯賬號',
    recalculationBureau: '重算遊戲局',
    limitSelect: '限紅選擇'
  },
  page: {
    welcome: '歡迎登錄真人視訊遊戲管理後臺',
    title: '遊戲管理後臺',
    dragInfo: '可左右拖拽分配',
    dragSort: '可上下拖拽排序',
    newPlan: '新建方案',
    editPlan: '編輯方案',
    setChip: '設置籌碼',
    limitNewInfo: '玩法的限紅將根據最小最大投註以及玩法賠率自動生成',
    notSupport: '您的瀏覽器不支持此視頻'
  },
  navbar: {
    dashboard: '首頁',
    github: '项目地址',
    logOut: '退出賬號',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '真人視訊遊戲管理後臺',
    logIn: '登錄',
    username: '請輸入賬號',
    password: '請輸入密碼',
    codeVerify: '請輸入驗證碼',
    verificationCode: '驗證碼',
    less6Password: '密碼不能少於6位'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  tips: {
    delPaiTip: '你確定刪除此牌嗎?',
    setDefaultSelectedChip: '请从以上筹码中选择1个作为默认选中筹码'
  }
};
exports.default = _default;